import axios from "axios"
import { createZendeskTemplate } from "../utils/createZendeskTemplate"
import {
  GATSBY_ENV,
  GATSBY_ZENDESK_STATE_CUSTOM_FIELD_ID,
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_SUBMIT_ORDER_FORM_WEBHOOK,
  GATSBY_IS_AUTH_ACTIVATED,
} from "gatsby-env-variables"
import {
  generateUploadFiles,
  zendeskApi,
  zendeskUploadFiles,
} from "../../../services/zendeskService"
import { getSignedInUser } from "../../Auth/services/user"
import { isObjectEmpty } from "../../../services/general"
import { updateUserDocument } from "./user"

const TEST_ENV = GATSBY_ENV === "production" ? "" : "[TEST] "

const createZendeskRequestTemplate = ({ state, formFields }) => {
  let tags = [`orders`, "saxenda"]
  const subject =
    "Liraglutide (Saxenda®) Patient Support Program: Your Order has been Received"
  if (GATSBY_ENV !== "production") tags.push("test")

  let newState = { ...state }

  return {
    request: {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${state?.firstName} ${state?.lastName}` || "",
        email: state?.email || "it@medgrocer.com",
      },
      comment: {
        html_body: createZendeskTemplate({ state, formFields }),
      },
      custom_fields: [
        {
          id: GATSBY_ZENDESK_STATE_CUSTOM_FIELD_ID,
          value: JSON.stringify({ ...newState }),
        },
      ],
      tags,
    },
  }
}

export const handleSubmitOrder = async ({ state, formFields, module }) => {
  let mgcareZendeskConfig = {
    email: GATSBY_ZENDESK_EMAIL,
    apiKey: GATSBY_ZENDESK_API_KEY,
    apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
  }

  let { name } = module

  let generatedFileTokens = []
  if (state?.documents?.length > 0) {
    const uploadedFiles = await zendeskUploadFiles(
      state?.documents,
      null,
      mgcareZendeskConfig
    )
    generatedFileTokens = await generateUploadFiles(uploadedFiles)
    // delete state.documents
  }

  let template = createZendeskTemplate({
    state,
    formFields,
  })

  let requestTemplate = createZendeskRequestTemplate({
    state,
    name,
    formFields,
  })

  try {
    const comment = {
      ...requestTemplate.request.comment,
      uploads: generatedFileTokens,
    }
    requestTemplate = { request: { ...requestTemplate.request, comment } }

    await zendeskApi({ ...mgcareZendeskConfig }).post(
      "/requests.json",
      requestTemplate
    )

    let { userData, authUser } = getSignedInUser()

    if (GATSBY_IS_AUTH_ACTIVATED && !isObjectEmpty(userData))
      await updateUserDocument({
        user: userData,
        values: state,
        authUid: authUser.uid,
      })
  } catch (e) {
    await axios.post(GATSBY_SUBMIT_ORDER_FORM_WEBHOOK, {
      ...state,
      template,
      uploads: generatedFileTokens,
    })
  }
}
