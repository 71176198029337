import React from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

import Button from "elements/Button"
import styles from "./utils/elements.module.scss"

const EditDetailsButton = ({ route = "#", onClick, className, children }) => {
  return (
    <Link
      to={route}
      onClick={onClick}
      className={classNames(
        styles["section__buttonBorderless"],
        className ||
          "has-text-secondary is-flex is-align-items-center button-size"
      )}
    >
      <span
        className="icon"
        style={{
          fontSize: ".75rem",
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </span>
      <span
        className="is-underlined has-text-weight-bold"
        style={{
          fontSize: ".75rem",
        }}
      >
        {children || "Edit"}
      </span>
    </Link>
  )
}

export default EditDetailsButton
