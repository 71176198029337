import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import styles from "../utils/epharmacy.module.scss"

const FileThumbnail = ({ file, isPdf }) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed

  return (
    <Fragment>
      <figure className={classNames("is-flex is-align-items-center")}>
        {isPdf ? (
          <Img fixed={pdfThumbnail} alt="pdfThumbnail" />
        ) : (
          <figure>
            <img
              src={file.path}
              alt="attachment"
              className={`image ${styles["column__figureMaxSize"]}`}
            />
          </figure>
        )}
        <div className="is-flex is-flex-direction-column">
          <span
            className={classNames(``, {
              "pl-1": !isPdf,
            })}
          >
            {file?.name}
          </span>
        </div>
      </figure>
    </Fragment>
  )
}

export default FileThumbnail
