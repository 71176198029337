import React from "react"
import classNames from "classnames"

import {
  generatePrice,
  formatPrice,
} from "../../Epharmacy/services/computations"

const MedicineInfoRow = ({ medicine }) => {
  const assistanceProductTitle = medicine?.productTitle[0].replace(
    ")",
    " - Medicine Assistance)"
  )
  return (
    <div className={classNames("pt-1 pb-1 has-border-bottom")}>
      <div className="has-text-primary has-text-weight-bold">
        {medicine?.productTitle}
      </div>
      <div className="columns is-mobile">
        <div className="column">Quantity: {medicine.qty}</div>
        <div className={classNames("column has-text-right")}>
          Php {formatPrice((generatePrice(medicine) * medicine.qty).toFixed(2))}
        </div>
      </div>
      {medicine?.assistanceQuantity > 0 && (
        <span>
          <div className="has-text-weight-bold">Medicine Assistance</div>
          <div className="columns is-mobile">
            <div className="column">
              Quantity: {medicine.assistanceQuantity}
            </div>
            <div className={classNames("column has-text-right")}>Php 0.00</div>
          </div>
        </span>
      )}
    </div>
  )
}

export default MedicineInfoRow
