import axios from "axios"
import { get } from "lodash"
import { navigate } from "gatsby"
import classNames from "classnames"
import React, { useContext, useEffect, useState } from "react"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import SummaryConsent from "./SummaryConsent"
import EditDetailsButton from "elements/EditDetailsButton"
import MedicinesOrderedSummary from "./Summary/MedicinesOrderedSummary"
import UploadDocumentsSummary from "./Summary/UploadDocumentsSummary"

import styles from "./utils/epharmacy.module.scss"
import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"

import { generateUploadFiles } from "../../services/zendeskService"
//import { getSignedInUser } from "../Auth/services/user"
import {
  createAddressDocument,
  updateAddressDocument,
  updateUserDocument,
} from "./services/user"
import {
  accumulateQuantity,
  accumulatePrice,
  formatPrice,
} from "../Epharmacy/services/computations"
import { isBrowser } from "../../services/general"
import { handleSubmitOrder } from "./services/handleSubmitOrder"
import { useAirtableEpharmacyFormFields } from "./EpharmacyContext/hooks/useAirtableEpharmacyFormFields"
import { flattenNode } from "../../services/arrays"
import PaymentSummary from "./Summary/PaymentSummary"
import removeTags from "./utils/removeHTMLTags"

let { parseFormField } = require("../../services/airtable")

const Summary = ({ pageContext }) => {
  let userData = {} // getSignedInUser()?.userData

  const [loading, setLoading] = useState(false)
  const formFields = flattenNode(
    useAirtableEpharmacyFormFields().allAirtableallEpharmacyFormFields
  )
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const { module } = pageContext

  useEffect(() => {
    epharmacyDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [epharmacyDispatch])

  let sectionSummaryFields = parseFormField(
    formFields.map((formField) => ({
      ...formField,
      section: formField.section,
    }))
  )

  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSubmit = async (values, actions) => {
    setLoading(true)

    let hasUploadedValidID = false
    for (let i = 0; i < epharmacyState?.documents?.length; i++) {
      let name = epharmacyState?.documents[i]?.name
      name = name.substring(0, 2)
      if (name == "id") {
        hasUploadedValidID = true
        break
      }
    }
    try {
      setLoading(true)

      if (
        !("consentAndAuthorization" in values) ||
        values?.consentAndAuthorization?.length === 0
      ) {
        actions.setFieldError(
          "consentAndAuthorization",
          "This field is required"
        )
        setLoading(false)
      } else {
        await handleSubmitOrder({
          state: epharmacyState,
          module: pageContext?.module,
          formFields,
        })
        setLoading(false)
        navigate(pageContext?.nextPath)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      isPrivate={pageContext?.module?.isPrivate}
      display={{
        footer: false,
        helpCenterBanner: false,
        bottomMargin: true,
        progressBar: true,
      }}
    >
      <Container isCentered desktop={6}>
        {sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: <EditDetailsButton route={section.link} />,
              }}
              isSectionRequired={section?.isSectionRequired}
            >
              <div className="table-container">
                <table class="table is-fullwidth ">
                  <tbody>
                    {section.fields
                      .sort((firstField, secondField) => {
                        return firstField.order - secondField.order
                      })
                      .map((field) => {
                        let finalValue = null
                        switch (field.type) {
                          case "schedule":
                            finalValue = get(epharmacyState, field.name)
                              ? `${
                                  get(epharmacyState, field.name)?.day?.label
                                }, ${
                                  get(epharmacyState, field.name)?.time?.label
                                }`
                              : ""
                            break
                          case "select":
                          case "height":
                          case "weight":
                          case "hospital":
                            finalValue = get(epharmacyState, field.name)
                              ? get(epharmacyState, field.name)?.value ===
                                "Other"
                                ? get(epharmacyState, "otherHospital")
                                : get(epharmacyState, field.name)?.value
                              : ""
                            break
                          case "date":
                            finalValue = get(epharmacyState, field.name)
                              ? `${
                                  get(epharmacyState, field.name)?.month?.value
                                } ${
                                  get(epharmacyState, field.name)?.date?.value
                                } ${
                                  get(epharmacyState, field.name)?.year?.value
                                }`
                              : ""
                            break
                          case "address":
                            let streetAddress = get(epharmacyState, field.name)
                              ?.streetAddress
                            let barangay = get(epharmacyState, field.name)
                              ?.barangay?.value
                            let city = get(epharmacyState, field.name)?.city
                              ?.value
                            let province = get(epharmacyState, field.name)
                              ?.province?.value
                            finalValue = get(epharmacyState, field.name)
                              ? `${
                                  get(epharmacyState, field.name)?.streetAddress
                                }, ${
                                  get(epharmacyState, field.name)?.province
                                    ?.value
                                }, ${
                                  get(epharmacyState, field.name)?.city?.value
                                }, ${
                                  get(epharmacyState, field.name)?.barangay
                                    ?.value
                                }`.trim("")
                              : ""
                            if (field.name === "clinicAddress")
                              finalValue = finalValue.slice(1, -1) //finalValue = finalValue.replaceAll(/[,]/g, "");
                            break
                          default:
                            finalValue = get(epharmacyState, field.name)
                            if (field.name === "otherHospital")
                              finalValue = null
                            if (field.isContactNumber)
                              finalValue = `+63${finalValue}`
                            break
                        }

                        if (!!finalValue)
                          return (
                            <tr>
                              <td style={{ border: "none" }}>
                                <h4 className="has-text-weight-bold ">
                                  {field.label}
                                </h4>
                                <p>{removeTags(finalValue)}</p>
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}
        <MedicinesOrderedSummary
          route={`/epharmacy/order`}
          medicines={epharmacyState?.medicines}
          epharmacyState={epharmacyState}
          epharmacyDispatch={epharmacyDispatch}
        />
        <UploadDocumentsSummary
          route={`/${module?.name}/upload`}
          documents={epharmacyState?.documents}
        />
        <PaymentSummary
          route={`/${module?.name}/payment`}
          state={epharmacyState}
        />

        <Message color="warning">
          {
            "Our pharmacist will verify your order via SMS. Your order will be delivered within the next working day."
          }
        </Message>
        <SummaryConsent
          loading={loading}
          state={epharmacyState}
          pageContext={pageContext}
          handleSubmit={handleSubmit}
          dispatch={epharmacyDispatch}
        />

        {/* <BookingSummaryConsent
          sectionFormFields={sectionFormFields}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          pageContext={pageContext}
          loading={loading}
        /> */}
      </Container>
    </Layout>
  )
}

export default Summary
