import { formatDate } from "./formatDate"

export const createZendeskBodyFields = ({ module, state }) => {
  let template = ""
  module.fields.map((field) => {
    if (!!state[field.name]) {
      let key = field.name
      switch (field.type) {
        case "date":
          if (state[key]?.month?.value) {
            template =
              template +
              `${field.zendeskKey}: ` +
              formatDate({ date: state[key], format: "ll" }) +
              "<br/>"
          }
          break
        case "hospital":
        case "height":
        case "weight":
        case "select":
          if (state[key]?.value) {
            template =
              template + `${field.zendeskKey}: ${state[key]?.value}<br/>`
          }
          break
        case "checkbox":
          if (state[key]) {
            if (field.zendeskKey && state[key].length)
              template = template + `${field.zendeskKey}: ${state[key]}<br/>`
          }
          break
        case "address":
        case "siteAddress":
          if (state[key]) {
            let addressType = "Home"
            if (state[key].deliverToOffice?.length > 0) addressType = "Office"
            template =
              template +
              `Address Type: ${addressType}<br/>${
                addressType === "Office"
                  ? `Site Name: ${
                      state[key].siteName?.label || state[key].siteName || "N/A"
                    }<br/>`
                  : ``
              }Street Address: ${
                !!state[key].houseNumber ? `${state[key].houseNumber} ` : ""
              }${state[key].streetAddress}<br/>Province: ${
                state[key].province?.value
              }<br/>City: ${state[key].city?.value}<br/>Barangay: ${
                state[key].barangay?.value
              }<br/>`
          }
          break
        case "upload":
          template =
            template +
            `${state[key]?.uploadPrescription
              .map(
                (item) => item.filename + "." + item.oldname.split(".").at(-1)
              )
              .join("<br/>")}<br/>`
          break
        case "number":
          template = template + `${field.zendeskKey}: ${state[key]}<br/>`
          break
        case "radio":
        case "text":
          switch (true) {
            case field.zendeskKey.includes("FBS Result"):
              template = template + `${field.zendeskKey}: ${state[key]}`
              break
            case field.zendeskKey.includes("Hba1C Result"):
              template = template + `${field.zendeskKey}: ${state[key]}<br/>`
              break
            case field.zendeskKey.includes("LDL Result"):
              template = template + `${field.zendeskKey}: ${state[key]}`
              break
            case field.zendeskKey.includes("fbsUnitOfMeasure"):
              template = template + ` ${state[key]}<br/>`
              break
            case field.zendeskKey.includes("ldlUnitOfMeasure"):
              template = template + ` ${state[key]}<br/>`
              break
            case field.zendeskKey.includes("Mobile Number"):
            case field.zendeskKey.includes("Mobile Number (Secondary Contact)"):
            case field.zendeskKey.includes("Viber Number"):
              template = template + `${field.zendeskKey}: +63${state[key]}<br/>`
              break
            default:
              template = template + `${field.zendeskKey}: ${state[key]}<br/>`
              break
          }
          break
        case "textarea":
        default:
          if (state[key] && field.zendeskKey)
            template = template + `${field.zendeskKey}: ${state[key]}<br/>`
          break
      }
    }
  })
  return template
}
