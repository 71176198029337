import firebase from "firebase"
import { isBrowser } from "../../../services/general"

export const createAddressDocument = async ({ values }) => {
  let newAddressDocument = {
    addresses: [
      {
        type: values?.deliveryAddress?.addressType,
        streetAddress: values?.deliveryAddress?.streetAddress,
        city: values?.deliveryAddress?.city?.value,
        province: values?.deliveryAddress?.province?.value,
        barangay: values?.deliveryAddress?.barangay?.value,
        primary: true,
      },
    ],
  }

  let addressResponse = await firebase
    .firestore()
    .collection("addresses")
    .add({ ...newAddressDocument })

  let addressesUid = addressResponse.id

  if (isBrowser()) {
    sessionStorage.setItem(
      "addresses",
      JSON.stringify({ ...newAddressDocument, id: addressesUid })
    )
  }

  return addressResponse
}

export const updateAddressDocument = async ({
  values,
  addressesUid,
  oldAddresses,
}) => {
  let updatedAddressDocument = {
    addresses: [
      ...oldAddresses,
      {
        type: values?.address?.addressType,
        streetAddress: values?.address?.streetAddress,
        city: values?.address?.city?.value,
        province: values?.address?.province?.value,
        barangay: values?.address?.barangay?.value,
        primary: true,
      },
    ],
  }

  await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesUid)
    .update({ ...updatedAddressDocument })

  if (isBrowser()) {
    sessionStorage.setItem(
      "addresses",
      JSON.stringify({ ...updatedAddressDocument })
    )
  }
}

export const updateUserDocument = async ({ user, values, authUid }) => {
  let newUserDocument = {
    email: user?.email,
    firstName: values?.firstName,
    lastName: values?.lastName,
    middleInitial: values?.middleInitial,
    height: values?.height?.value || values?.height,
    weight: values?.weight?.value || values?.weight,
    prescribingDoctor: values?.prescribingDoctor,
    mobileNumber: `+63${values?.mobileNumber}`,
    viberNumber: `+63${values?.viberNumber}`,
    authorizedPerson: values?.authorizedPerson,
    authorizedPersonContactNumber: `+63${values?.authorizedPersonContactNumber}`,
  }

  if (authUid) {
    newUserDocument.authUid = authUid
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(user?.id)
    .update({ ...newUserDocument })

  sessionStorage.setItem(
    "userData",
    JSON.stringify({ ...user, ...newUserDocument })
  )
}
