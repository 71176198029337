import moment from "moment"

export const formatDate = ({ date, format }) => {
  return moment(
    new Date(
      `${date?.month?.label} ${
        date?.date?.label ? `${date?.date?.label}, ` : ""
      }${date?.year ? `${date?.year?.label}` : ""}`
    )
  ).format(format)
}