import { Form, Formik } from "formik"
import React, { Fragment, useContext } from "react"

import FormCheckbox from "elements/Form/FormCheckbox"
import ActionButtons from "elements/ActionButtons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const SummaryConsent = ({
  state,
  loading,
  dispatch,
  pageContext,
  handleSubmit,
  t,
}) => {
  const TERMS_AND_CONDITIONS_BLURB = `<span>I agree to the <a href="/terms-and-conditions" target="_blank" class="has-text-secondary has-text-weight-bold is-underlined"><b>terms and conditions</b></a>.</span>`
  const PILL_SPLIT_BLURB = `<span>I agree that my order will not be served if the prescription provided indicates cutting tables in half (e.g. Sacubitril-Valsartan 200mg tablet, 1/2 tablet twice a day) and I will visit my doctor to get an updated prescription for whole tablet intake.</span>`

  return (
    <Formik
      initialValues={{ consentAndAuthorization: [], ...state }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-3">
            <FormCheckbox
              name="consentAndAuthorization"
              values={values.consentAndAuthorization}
              options={[TERMS_AND_CONDITIONS_BLURB]}
              onChange={(event) =>
                dispatch({
                  type: "SAVE_AGREE_CONSENT",
                  payload: event.target.checked
                    ? [TERMS_AND_CONDITIONS_BLURB]
                    : [],
                })
              }
              isRequired
            />
          </div>

          <ActionButtons
            back={{
              label: <FontAwesomeIcon icon={faChevronLeft} />,
              link: pageContext.backPath,
            }}
            submit={{
              loading,
              label: "Submit",
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SummaryConsent
