import { formatPrice } from "../services/computations"
import { createZendeskBodyFields } from "./createZendeskBodyFields"

export const createZendeskTemplate = ({ state, formFields }) => {
  let moduleNames = [
    ...new Set(
      formFields
        .filter((field) => field.zendeskOrder)
        .map((field) => field.section)
    ),
  ]

  let zendeskOrder = [
    ...new Set(
      formFields
        .filter((field) => field.zendeskOrder)
        .map((field) => field.zendeskOrder)
    ),
  ]

  moduleNames = moduleNames.map((item) => ({
    section: item,
  }))

  moduleNames.forEach((moduleName, index) => {
    let filteredFormFields = formFields.filter(
      (field) =>
        field.section === moduleName.section && field.showOnZendesk === true
    )

    filteredFormFields = filteredFormFields.map((field) => {
      return {
        name: field.name,
        type: field.type,
        zendeskKey: field.zendeskKey,
      }
    })

    moduleNames[index].fields = filteredFormFields
    moduleNames[index].fields.reverse()
  })

  moduleNames.forEach(
    (moduleName, index) => (moduleName.zendeskOrder = zendeskOrder[index])
  )

  moduleNames = moduleNames.sort((a, b) => a.zendeskOrder - b.zendeskOrder)

  let template = ""

  moduleNames.map((module) => {
    template = template + `<strong>-- ${module.section} --</strong><br/><br/>`

    template =
      template +
      createZendeskBodyFields({
        module: module,
        state: state,
      })

    template = template + "<br/>"
  })

  let meds = state?.medicines[0]

  let documents = state?.documents

  let prescriptionBlurb =
    "<strong>-- Prescription --</strong><br/><br/>" +
    documents
      .map((item) => item.filename + "." + item.oldname.split(".").at(-1))
      .join("<br/>") +
    "<br/><br/>"

  let medicineBlurb =
    "<strong>-- Quantity --</strong><br/><br/>" +
    meds.qty +
    " " +
    meds.productTitle[0] +
    "<br/><br/>"

  let changeFor =
    state?.paymentOption === "Cash on Delivery"
      ? "Change For: Php " + formatPrice(state?.changeFor)
      : "Preferred Bank: " + state?.preferredBank

  let paymentBlurb =
    "<strong>-- Payment Details --</strong><br/><br/>Payment Method: " +
    state?.paymentOption +
    "<br/>" +
    changeFor +
    "<br/>"

  template =
    template + prescriptionBlurb + medicineBlurb + paymentBlurb + "<br/>"

  return template
}
