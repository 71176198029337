import React, { useContext } from "react"
import Section from "../../Elements/Section"
import Message from "../../Elements/Message"
import EditDetailsButton from "../../Elements/EditDetailsButton"
import MedicineInfoRow from "./MedicineInfoRow"
import { accumulatePrice, formatPrice } from "../services/computations"
import useSaxendaCoupons from "../Order/hooks/useSaxendaCoupons"
import useAssistanceCalculatorImages from "../Order/hooks/useAssistanceCalculatorImages"
import AddMedicineModal from "../AddMedicineModal"
import { AppContext } from "../../../context/AppContext"
import { medicine } from "../../Upload/utils/staticItems"
import { navigate } from "gatsby"

const MedicinesOrderedSummary = ({
  route,
  medicines,
  epharmacyState,
  epharmacyDispatch,
}) => {
  const orderTotal = formatPrice((accumulatePrice(medicines) + 100).toFixed(2))
  const paymentOption = epharmacyState?.paymentOption

  return (
    <Section
      title={`Medicines Requested`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
      isSectionRequired={true}
      className=""
    >
      {medicines?.length > 0 ? (
        <div className="mx-1">
          <div className="columns is-vcentered is-multiline">
            {medicines?.map((medicine) => (
              <div className="column is-12">
                <MedicineInfoRow medicine={medicine} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black ">You have not added any medicines.</p>
        </Message>
      )}
      <div className="pt-1 px-1 mb-3">
        <div className="columns is-mobile mb-0">
          <div className="column has-text-weight-bold">Aseptic Delivery</div>
          <div className="column has-text-right">Php 100.00</div>
        </div>
        <div className="columns is-mobile help mt-0">
          <div className="column has-text-left">
            To continuously deliver your medicines safely, we provide our riders
            the necessary precautionary protective gear and materials for every
            delivery transaction.
          </div>
        </div>
        <div className="columns is-mobile">
          <div className="column has-text-grey ">
            Order Total:{" "}
            <span className="has-text-black has-text-left  has-text-weight-bold">
              Php {orderTotal}
            </span>
          </div>
          <div className="column has-text-grey ">
            MOP:{" "}
            <span className="has-text-black has-text-left  has-text-weight-bold">
              {paymentOption}
            </span>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default MedicinesOrderedSummary
