import React from "react"
import Section from "elements/Section"

import EditDetailsButton from "elements/EditDetailsButton"
import { formatPrice } from "../services/computations"

const PaymentSummary = ({ state, route }) => {
  return (
    <Section
      title={`Payment Details`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
      isSectionRequired={true}
      className=" mb-2"
    >
      <div className="table-container mt-1">
        <table class="table is-fullwidth ">
          <tbody>
            <tr>
              <td style={{ border: "none" }}>
                <h4 className="has-text-weight-bold ">Payment Method:</h4>
                <p>
                  {state?.paymentOption}
                  {state?.paymentOption === "Bank Deposit" &&
                    ": " + state?.preferredBank}
                </p>
              </td>
            </tr>
            {state?.paymentOption?.toLowerCase() === "cash on delivery" && (
              <tr>
                <td style={{ border: "none" }}>
                  <h6 className="has-text-weight-bold ">Change For:</h6>
                  <p className="helper">Php {formatPrice(state?.changeFor)}</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default PaymentSummary
